export const STORE_TEST_INFO = "STORE_TEST_INFO";
export const STORE_BULK_STATIC_CONTENT = "STORE_BULK_STATIC_CONTENT";
export const STORE_SYSTEM_FLAG = "STORE_SYSTEM_FLAG";

// Redux Actions
export const REQUEST = "REQUEST";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const CLEAR = "CLEAR";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const UPDATE_MODAL_TITLE = "UPDATE_MODAL_TITLE";
export const STORE_FORM_IS_LOADING = "STORE_FORM_IS_LOADING";

// Document Viewer
export const OPEN_DOCUMENT_VIEWER = "OPEN_DOCUMENT_VIEWER";
export const CLOSE_DOCUMENT_VIEWER = "CLOSE_DOCUMENT_VIEWER";
export const UPDATE_DOCUMENT_VIEWER_TITLE = "UPDATE_DOCUMENT_VIEWER_TITLE";

export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const STORE_USER_ACCESS_DATA = "STORE_USER_ACCESS_DATA";
export const LOGOUT = "LOGOUT";
export const STORE_IS_PROPONENT = "STORE_IS_PROPONENT";

export const STORE_CURRENT_USER_MINE_VERIFIED_STATUS = "STORE_CURRENT_USER_MINE_VERIFIED_STATUS";
export const STORE_MINE_LIST = "STORE_MINE_LIST";
export const STORE_MINE = "STORE_MINE";
export const STORE_MINE_NAME_LIST = "STORE_MINE_NAME_LIST";
export const STORE_SUBSCRIBED_MINES = "STORE_SUBSCRIBED_MINES";
export const STORE_MINE_BASIC_INFO_LIST = "STORE_MINE_BASIC_INFO_LIST";
export const STORE_STATUS_OPTIONS = "STORE_STATUS_OPTIONS";
export const STORE_REGION_OPTIONS = "STORE_REGION_OPTIONS";
export const STORE_MINE_DOCUMENTS = "STORE_MINE_DOCUMENTS";
export const STORE_MINE_TSF_REQUIRED_DOCUMENTS = "STORE_MINE_TSF_REQUIRED_DOCUMENTS";
export const STORE_MINE_COMPLIANCE_INFO = "STORE_MINE_COMPLIANCE_INFO";
export const STORE_PROVINCE_OPTIONS = "STORE_PROVINCE_OPTIONS";

export const STORE_PARTY = "STORE_PARTY";
export const STORE_PARTIES = "STORE_PARTIES";
export const STORE_LAST_CREATED_PARTY = "STORE_LAST_CREATED_PARTY";
export const STORE_ADD_PARTY_FORM_STATE = "STORE_ADD_PARTY_FORM_STATE";
export const STORE_INSPECTORS = "STORE_INSPECTORS";
export const STORE_PROJECT_LEADS = "STORE_PROJECT_LEADS";

export const STORE_DOCUMENT_STATUS_OPTIONS = "STORE_DOCUMENT_STATUS_OPTIONS";
export const STORE_TENURE_TYPES = "STORE_TENURE_TYPES";
export const STORE_DISTURBANCE_OPTIONS = "STORE_DISTURBANCE_OPTIONS";
export const STORE_COMMODITY_OPTIONS = "STORE_COMMODITY_OPTIONS";

export const STORE_PARTY_RELATIONSHIP_TYPES = "STORE_PARTY_RELATIONSHIP_TYPES";
export const STORE_PARTY_RELATIONSHIPS = "STORE_PARTY_RELATIONSHIPS";
export const STORE_ALL_PARTY_RELATIONSHIPS = "STORE_ALL_PARTY_RELATIONSHIPS";

export const STORE_MINESPACE_USERS = "STORE_MINESPACE_USERS";
export const STORE_MINESPACE_USER_MINES = "STORE_MINESPACE_USER_MINES";

export const STORE_MINISTRY_CONTACTS = "STORE_MINISTRY_CONTACTS";
export const STORE_MINISTRY_CONTACTS_BY_REGION = "STORE_MINISTRY_CONTACTS_BY_REGION";

export const STORE_PROJECT_LINKS = "STORE_PROJECT_LINKS";
export const REMOVE_PROJECT_LINK = "REMOVE_PROJECT_LINK";

// Permits
export const STORE_PERMITS = "STORE_PERMITS";
export const STORE_DRAFT_PERMITS = "STORE_DRAFT_PERMITS";
export const STORE_PERMIT_STATUS_OPTIONS = "STORE_PERMIT_STATUS_OPTIONS";
export const STORE_PERMIT_CONDITIONS = "STORE_PERMIT_CONDITIONS";
export const STORE_EDITING_CONDITION_FLAG = "STORE_EDITING_CONDITION_FLAG";
export const STORE_STANDARD_PERMIT_CONDITIONS = "STORE_STANDARD_PERMIT_CONDITIONS";
export const STORE_EDITING_PREAMBLE_FLAG = "STORE_EDITING_PREAMBLE_FLAG";

export const CREATE_PERMIT_CONDITION_CATEGORY = "CREATE_PERMIT_CONDITION_CATEGORY";
export const STORE_PERMIT_CONDITION_CATEGORY = "STORE_PERMIT_CONDITION_CATEGORY";

// Permit Notices of Departure
export const STORE_NOTICES_OF_DEPARTURE = "STORE_NOTICES_OF_DEPARTURE";
export const STORE_NOTICE_OF_DEPARTURE = "STORE_NOTICE_OF_DEPARTURE";

// Explosive Storage & Use Permits
export const STORE_EXPLOSIVES_PERMITS = "STORE_EXPLOSIVES_PERMITS";

// Search
export const STORE_SEARCH_OPTIONS = "STORE_SEARCH_OPTIONS";
export const STORE_SEARCH_RESULTS = "STORE_SEARCH_RESULTS";
export const STORE_SEARCH_BAR_RESULTS = "STORE_SEARCH_BAR_RESULTS";
export const CLEAR_SEARCH_BAR_RESULTS = "CLEAR_SEARCH_BAR_RESULTS";
export const CLEAR_ALL_SEARCH_RESULTS = "CLEAR_ALL_SEARCH_RESULTS";
export const STORE_SUBSET_SEARCH_RESULTS = "STORE_SUBSET_SEARCH_RESULTS";

// Variances
export const STORE_VARIANCES = "STORE_VARIANCES";
export const STORE_VARIANCE = "STORE_VARIANCE";
export const STORE_COMPLIANCE_CODES = "STORE_COMPLIANCE_CODES";
export const STORE_VARIANCE_STATUS_OPTIONS = "STORE_VARIANCE_STATUS_OPTIONS";
export const STORE_VARIANCE_DOCUMENT_CATEGORY_OPTIONS = "STORE_VARIANCE_DOCUMENT_CATEGORY_OPTIONS";

// Projects
export const STORE_PROJECTS = "STORE_PROJECTS";
export const STORE_PROJECT = "STORE_PROJECT";
export const CLEAR_PROJECT = "CLEAR_PROJECT";
export const STORE_PROJECT_SUMMARIES = "STORE_PROJECT_SUMMARIES";
export const STORE_PROJECT_SUMMARY = "STORE_PROJECT_SUMMARY";
export const CLEAR_PROJECT_SUMMARY = "CLEAR_PROJECT_SUMMARY";
export const STORE_PROJECT_SUMMARY_MINISTRY_COMMENTS = "STORE_PROJECT_SUMMARY_MINISTRY_COMMENTS";
export const CLEAR_PROJECT_SUMMARY_MINISTRY_COMMENTS = "CLEAR_PROJECT_SUMMARY_MINISTRY_COMMENTS";
export const ADD_PROJECT_SUMMARY_MINISTRY_COMMENT = "ADD_PROJECT_SUMMARY_MINISTRY_COMMENT";
export const POST_NEW_DOCUMENT_VERSION = "POST_NEW_DOCUMENT_VERSION";
export const STORE_AUTHORIZATIONS = "STORE_AUTHORIZATIONS";
export const STORE_INFORMATION_REQUIREMENTS_TABLE = "STORE_INFORMATION_REQUIREMENTS_TABLE";
export const CLEAR_INFORMATION_REQUIREMENTS_TABLE = "CLEAR_INFORMATION_REQUIREMENTS_TABLE";
export const STORE_REQUIREMENTS = "STORE_REQUIREMENTS";
export const CLEAR_REQUIREMENTS = "CLEAR_REQUIREMENTS";
export const STORE_MAJOR_MINES_APPLICATION = "STORE_MAJOR_MINES_APPLICATION";
export const CLEAR_MAJOR_MINES_APPLICATION = "CLEAR_MAJOR_MINES_APPLICATION";
export const STORE_PROJECT_DECISION_PACKAGE = "STORE_PROJECT_DECISION_PACKAGE";
export const CLEAR_PROJECT_DECISION_PACKAGE = "CLEAR_PROJECT_DECISION_PACKAGE";
export const STORE_PROJECT_VIEW_ALL_TABLE = "STORE_PROJECT_VIEW_ALL_TABLE";
export const CLEAR_PROJECT_VIEW_ALL_TABLE = "CLEAR_PROJECT_VIEW_ALL_TABLE";

// User
export const STORE_CORE_USERS = "STORE_CORE_USERS";

// Incidents
export const STORE_INCIDENTS = "STORE_INCIDENTS";
export const STORE_MINE_INCIDENTS = "STORE_MINE_INCIDENTS";
export const STORE_MINE_INCIDENT = "STORE_MINE_INCIDENT";
export const STORE_MINE_INCIDENT_NOTES = "STORE_MINE_INCIDENT_NOTES";
export const STORE_INCIDENT_DOCUMENT_TYPE_OPTIONS = "STORE_INCIDENT_DOCUMENT_TYPE_OPTIONS";
export const STORE_MINE_INCIDENT_FOLLOWUP_ACTION_OPTIONS =
  "STORE_MINE_INCIDENT_FOLLOWUP_ACTION_OPTIONS";
export const STORE_MINE_INCIDENT_DETERMINATION_OPTIONS =
  "STORE_MINE_INCIDENT_DETERMINATION_OPTIONS";
export const STORE_MINE_INCIDENT_STATUS_CODE_OPTIONS = "STORE_MINE_INCIDENT_STATUS_CODE_OPTIONS";
export const STORE_MINE_INCIDENT_CATEGORY_CODE_OPTIONS =
  "STORE_MINE_INCIDENT_CATEGORY_CODE_OPTIONS";
export const CLEAR_MINE_INCIDENT = "CLEAR_MINE_INCIDENT";
export const REMOVE_MINE_INCIDENT_DOCUMENT = "REMOVE_MINE_INCIDENT_DOCUMENT";

// Work Information
export const STORE_MINE_WORK_INFORMATIONS = "STORE_MINE_WORK_INFORMATIONS";

// Reports
export const STORE_REPORTS = "STORE_REPORTS";
export const STORE_MINE_REPORTS = "STORE_MINE_REPORTS";
export const CLEAR_MINE_REPORTS = "CLEAR_MINE_REPORTS";
export const STORE_MINE_REPORT = "STORE_MINE_REPORT";
export const STORE_MINE_REPORT_COMMENTS = "STORE_MINE_REPORT_COMMENTS";
export const STORE_MINE_REPORT_STATUS_OPTIONS = "STORE_MINE_REPORT_STATUS_OPTIONS";
export const STORE_MINE_REPORT_CATEGORY_OPTIONS = "STORE_MINE_REPORT_CATEGORY_OPTIONS";

// notice of work
export const STORE_NOTICE_OF_WORK_APPLICATIONS = "STORE_NOTICE_OF_WORK_APPLICATIONS";
export const STORE_NOTICE_OF_WORK_APPLICATION = "STORE_NOTICE_OF_WORK_APPLICATION";
export const CLEAR_NOTICE_OF_WORK_APPLICATION = "CLEAR_NOTICE_OF_WORK_APPLICATION";
export const STORE_MINE_NOTICE_OF_WORK_APPLICATIONS = "STORE_MINE_NOTICE_OF_WORK_APPLICATIONS";
export const STORE_ORIGINAL_NOTICE_OF_WORK_APPLICATION =
  "STORE_ORIGINAL_NOTICE_OF_WORK_APPLICATION";
export const STORE_NOTICE_OF_WORK_ACTIVITY_TYPE_OPTIONS =
  "STORE_NOTICE_OF_WORK_ACTIVITY_TYPE_OPTIONS";
export const STORE_NOTICE_OF_WORK_UNIT_TYPE_OPTIONS = "STORE_NOTICE_OF_WORK_UNIT_TYPE_OPTIONS";
export const STORE_NOTICE_OF_WORK_APPLICATION_TYPE_OPTIONS =
  "STORE_NOTICE_OF_WORK_APPLICATION_TYPE_OPTIONS";
export const STORE_NOTICE_OF_WORK_APPLICATION_STATUS_OPTIONS =
  "STORE_NOTICE_OF_WORK_APPLICATION_STATUS_OPTIONS";
export const STORE_NOTICE_OF_WORK_APPLICATION_DOCUMENT_TYPE_OPTIONS =
  "STORE_NOTICE_OF_WORK_APPLICATION_DOCUMENT_TYPE_OPTIONS";
export const STORE_NOW_UNDERGROUND_EXPLORATION_TYPE_OPTIONS =
  "STORE_NOW_UNDERGROUND_EXPLORATION_TYPE_OPTIONS";
export const STORE_NOTICE_OF_WORK_APPLICATION_REVIEWS = "STORE_NOTICE_OF_WORK_APPLICATION_REVIEWS";
export const STORE_NOTICE_OF_WORK_APPLICATION_REVIEW_TYPES =
  "STORE_NOTICE_OF_WORK_APPLICATION_REVIEW_TYPES";
export const STORE_NOW_APPLICATION_PROGRESS_STATUS_CODES =
  "STORE_NOW_APPLICATION_PROGRESS_STATUS_CODES";
export const STORE_NOW_APPLICATION_PERMIT_TYPES = "STORE_NOW_APPLICATION_PERMIT_TYPES";
export const STORE_NOTICE_OF_WORK_APPLICATION_DOCUMENT_DOWNLOAD_STATE =
  "STORE_NOTICE_OF_WORK_APPLICATION_DOCUMENT_DOWNLOAD_STATE";
export const STORE_IMPORT_NOTICE_OF_WORK_SUBMISSION_DOCUMENTS_JOB =
  "STORE_IMPORT_NOTICE_OF_WORK_SUBMISSION_DOCUMENTS_JOB";
export const STORE_NOTICE_OF_WORK_APPLICATION_DELAY = "STORE_NOTICE_OF_WORK_APPLICATION_DELAY";

// securities
export const STORE_MINE_BONDS = "STORE_MINE_BONDS";
export const STORE_MINE_RECLAMATION_INVOICES = "STORE_MINE_RECLAMATION_INVOICES";

// comments
export const STORE_MINE_COMMENTS = "STORE_MINE_COMMENTS";

// OrgBook
export const STORE_ORGBOOK_SEARCH_RESULTS = "STORE_ORGBOOK_SEARCH_RESULTS";
export const STORE_ORGBOOK_CREDENTIAL = "STORE_ORGBOOK_CREDENTIAL";

// EPIC Information
export const STORE_MINE_EPIC_INFO = "STORE_MINE_EPIC_INFO";

// Activities
export const STORE_ACTIVITIES = "STORE_ACTIVITIES";

// Tailings Storage Facilities
export const STORE_TAILINGS_STORAGE_FACILITY = "STORE_TAILINGS_STORAGE_FACILITY";
export const CLEAR_TAILINGS_STORAGE_FACILITY = "CLEAR_TAILINGS_STORAGE_FACILITY";

// Dams
export const STORE_DAM = "STORE_DAM";

//Verifiable Credentials
export const STORE_VC_WALLET_CONNECTION_INVITATION = "STORE_VC_WALLET_CONNECTION_INVITATION";
