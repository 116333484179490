// Redux Actions
export const REQUEST = "REQUEST";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const CLEAR = "CLEAR";

// Stores
export const STORE_USER_MINE_INFO = "STORE_USER_MINE_INFO";
export const STORE_MINE = "STORE_MINE";
export const STORE_MINE_DOCUMENTS = "STORE_MINE_DOCUMENTS";

// Authentication
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const LOGOUT = "LOGOUT";
export const STORE_IS_PROPONENT = "STORE_IS_PROPONENT";

// Modals
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const CHANGE_MODAL_TITLE = "CHANGE_MODAL_TITLE";

// Variances
export const STORE_VARIANCES = "STORE_VARIANCES";
export const STORE_VARIANCE = "STORE_VARIANCE";
export const STORE_COMPLIANCE_CODES = "STORE_COMPLIANCE_CODES";
export const STORE_VARIANCE_STATUS_OPTIONS = "STORE_VARIANCE_STATUS_OPTIONS";
export const STORE_VARIANCE_DOCUMENT_CATEGORY_OPTIONS = "STORE_VARIANCE_DOCUMENT_CATEGORY_OPTIONS";

// Reports
export const STORE_MINE_REPORTS = "STORE_MINE_REPORTS";
